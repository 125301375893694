// https://typescript.nuxtjs.org/cookbook/plugins/#iii-combined-inject
import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxt) => {
  const isProd = process.env.NODE_ENV === 'production'

  if (isProd) {
    const { sentryDSN, buildVersion } = useRuntimeConfig().public
    // https://docs.sentry.io/platforms/javascript/guides/vue/
    Sentry.init({
      app: nuxt.vueApp,
      release: buildVersion || '',
      dsn: sentryDSN || 'https://45cc1b08df344bfa848def6f2026e96f@sentry.medrocket.ru/69',
      integrations: [
        Sentry.browserTracingIntegration({ router: useRouter() }),
        Sentry.replayIntegration(),
      ],
      normalizeDepth: 10,
      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0,
    })
  }
})

